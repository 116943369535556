import React from 'react';

import { SVGIcon, SVGIconProps } from '../../primitives/SVGIcon';

const IconReport = (props: SVGIconProps) => {
  return (
    <SVGIcon {...props}>
      <rect
        x="4.75"
        y="13.75"
        width="2.5"
        height="5.5"
        rx="1"
        stroke="#000"
        strokeWidth="1.5"
      />
      <rect
        x="16.75"
        y="11.75"
        width="2.5"
        height="7.5"
        rx="1"
        stroke="#000"
        strokeWidth="1.5"
      />
      <rect
        x="10.75"
        y="4.75"
        width="2.5"
        height="14.5"
        rx="1"
        stroke="#000"
        strokeWidth="1.5"
      />
    </SVGIcon>
  );
};

export { IconReport };
