function getPlatform() {
  const pl = navigator?.platform.toUpperCase() ?? '';
  if (pl.indexOf('MAC') >= 0) {
    return 'mac';
  } else if (pl.match(/(iPhone|iPod|iPad)/i)) {
    return 'ios';
  } else if (pl.match(/Win32/i)) {
    return 'win';
  }
}

const getUserAgent = () => {
  const ua = navigator.userAgent?.toLowerCase() ?? '';
  if (/Edge\//i.test(ua)) {
    return 'edge';
  } else if (/^((?!chrome|android).)*safari/i.test(ua)) {
    return 'safari';
  } else if (/chrome/i.test(ua)) {
    return 'chrome';
  } else if (/firefox/i.test(ua)) {
    return 'firefox';
  } else if (/MSIE /i.test(ua) || /Trident\//i.test(ua)) {
    return 'ie';
  } else if ('opera' in window) {
    return 'opera';
  }
};

export const userAgent = getUserAgent();
export const platform = getPlatform();
export const isSafari = userAgent === 'safari';
