import React, { useMemo } from 'react';
import { t, Trans } from '@lingui/macro';
import cs from 'classnames';

import { keyBy } from '@float/common/lib/keyBy';
import { formatRateWithCurrencySymbol } from '@float/common/lib/rates/rates';
import { getCurrencyProps } from '@float/common/selectors/currentUser';
import { getRolesOptions } from '@float/common/selectors/roles';
import VirtualSelect from '@float/ui/deprecated/VirtualSelect/VirtualSelect';

import * as styles from './PersonRole.css';

export type PersonRoleProps = {
  roleId: number | null;
  rolesOptions: ReturnType<typeof getRolesOptions>;
  currencyConfig: ReturnType<typeof getCurrencyProps>;
  hasCustomRate: boolean;
  showRates: boolean;
  onRoleChange: (roleId: number | null) => void;
  placeholder?: string;
};

export const PersonRole = (props: PersonRoleProps) => {
  const {
    currencyConfig,
    hasCustomRate,
    onRoleChange,
    placeholder,
    roleId,
    rolesOptions,
    showRates,
  } = props;

  const rolesOptionsById = useMemo(
    () => keyBy(rolesOptions, 'value'),
    [rolesOptions],
  );

  const rolesOptionsWithRatesElements = useMemo(
    () =>
      rolesOptions?.map((option) => ({
        ...option,
        iconRight:
          showRates && option.defaultHourlyRate ? (
            <span className={styles.roleRate}>
              <Trans>
                {formatRateWithCurrencySymbol(
                  option.defaultHourlyRate,
                  currencyConfig,
                )}{' '}
                /hr
              </Trans>
            </span>
          ) : null,
      })),
    [rolesOptions, currencyConfig, showRates],
  );

  const roleOptionSelected =
    roleId && rolesOptionsById[roleId] ? rolesOptionsById[roleId] : null;

  const selectedRoleRateElement =
    showRates && roleOptionSelected && roleOptionSelected.defaultHourlyRate ? (
      <span
        className={cs(
          styles.roleRateSelected,
          hasCustomRate && styles.roleRateSelectedInactive,
        )}
      >
        <Trans>
          {formatRateWithCurrencySymbol(
            roleOptionSelected.defaultHourlyRate,
            currencyConfig,
          )}{' '}
          /hr
        </Trans>
      </span>
    ) : null;

  return (
    <div className={styles.wrapper}>
      <VirtualSelect
        data-testid="personRole"
        // @ts-expect-error virtual select is not typed
        autoSelectOnBlur
        clearInputOnDropdownOpen={false}
        creatable
        hideSelectedIcon={true}
        label={t`Role`}
        onChange={onRoleChange}
        options={rolesOptionsWithRatesElements}
        placeholder={placeholder}
        suffix={selectedRoleRateElement}
        value={roleId}
        visibleItems={6}
      />
    </div>
  );
};
