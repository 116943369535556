import React, { useEffect } from 'react';
import {
  getActivePmIntegration,
  getUser,
  shouldShowPmSidebar,
} from 'selectors';

import { NavIconBtn } from '@float/common/components/NavIconBtn';
import Share from '@float/common/components/Schedule/nav/Share';
import WeekNav, {
  DesktopOnlySpacer,
} from '@float/common/components/Schedule/nav/WeekNav';
import HorizontalZoom from '@float/common/components/Schedule/nav/ZoomHorizontal';
import { LoggedTimeHeader } from '@float/common/components/Schedule/util/LoggedTimeHeader';
import WeekRangePicker from '@float/common/components/WeekRangePicker';
import { userCanOnlyViewThemself } from '@float/common/lib/rights';
import { getActiveFilters } from '@float/common/selectors/views';
import {
  useAppDispatch,
  useAppSelector,
  useAppSelectorStrict,
} from '@float/common/store';
import useMedia from '@float/libs/web/hooks/useMedia';
import {
  Flex,
  Icons,
  Spacer,
  TextTooltip,
  TooltipWithHintAndMenu,
} from '@float/ui/deprecated';
import { togglePmSidebar } from '@float/web/pmSidebar/actions';
import { integrationTitles } from '@float/web/pmSidebar/constants';
import { useNavUpdater } from 'components/Nav/NavContext';

import VerticalZoom from './ZoomVertical';

const ViewControls = ({
  dates,
  visibleRangeRef,
  actions,
  logMyTimeView,
  suvWeek,
  setSuvWeek,
  isMobile,
  meFilter,
  canOnlyViewSelf,
}) => {
  let zoomAdded = false;

  const renderZoomControls = () => {
    const controls = [];

    if (!isMobile) {
      if (!logMyTimeView) {
        controls.push(
          <HorizontalZoom key="horizontal-zoom" actions={actions} />,
        );
        zoomAdded = true;
      }

      if (!(meFilter || canOnlyViewSelf || logMyTimeView)) {
        if (zoomAdded) {
          controls.push(<Spacer key="zoom-spacer" size={8} />);
        }

        controls.push(<VerticalZoom key="vertical-zoom" />);
        zoomAdded = true;
      }
    }

    return controls;
  };

  return (
    <>
      <Flex key="controls" data-product-tour-id="quick-controls">
        <WeekNav
          key="week-nav"
          dates={dates}
          visibleRangeRef={visibleRangeRef}
          actions={actions}
          logMyTimeView={logMyTimeView}
          suvWeek={suvWeek}
          setSuvWeek={setSuvWeek}
        />

        <DesktopOnlySpacer size={8} />

        {renderZoomControls()}
      </Flex>

      {zoomAdded && <Spacer key="spacer" size={16} />}
    </>
  );
};

export default function useEnsureScheduleControls({
  logTimeView,
  logMyTimeView,
  viewType,
  actions,
  visibleRangeRef,
  dates,
  suvWeek,
  setSuvWeek,
  mondayStart,
  suvDate,
}) {
  const user = useAppSelectorStrict(getUser);
  const filters = useAppSelectorStrict(getActiveFilters);
  const meFilter = user.prefs?.me_filter;
  const isMobile = useMedia() === 'small';
  const navCtx = useNavUpdater();
  const hasIntegrationEnabled = useAppSelector(shouldShowPmSidebar);
  const activePmIntegration = useAppSelector(getActivePmIntegration);
  const isObserver = user.account_type_id === 4;
  const canOnlyViewSelf = isObserver && userCanOnlyViewThemself(user);
  const isSharedLinkView = user.shared_link_view;
  const dispatch = useAppDispatch();

  useEffect(
    () => {
      const quickActionControls = [];
      const leftControls = [];

      if (logMyTimeView) {
        leftControls.push(
          <WeekRangePicker
            key="week-range"
            showToday={false}
            showArrows={false}
            currentWeek={dates.getCurrentWeek()}
            suvWeek={suvWeek}
            firstOfWeek={mondayStart ? 1 : 0}
            value={suvDate}
            handleSelect={(startDate) => {
              const date = startDate.format('YYYY-MM-DD');
              const [colIdx] = dates.toDescriptor(date);
              setSuvWeek(colIdx);
            }}
          />,
        );
      }

      quickActionControls.push(
        <ViewControls
          dates={dates}
          visibleRangeRef={visibleRangeRef}
          actions={actions}
          logMyTimeView={logMyTimeView}
          suvWeek={suvWeek}
          setSuvWeek={setSuvWeek}
          isMobile={isMobile}
          meFilter={meFilter}
          canOnlyViewSelf={canOnlyViewSelf}
        />,
      );

      if (!isMobile) {
        if (!isSharedLinkView && !logTimeView) {
          quickActionControls.push(
            <TooltipWithHintAndMenu
              key="share"
              distance={5}
              arrow={false}
              placement="bottom"
              hint={'Share'}
              menu={(showMenu) => (
                <Share
                  user={user}
                  actions={actions}
                  onClick={(actionCallback) => {
                    // Ensure the menu is closed before executing the action.
                    // In case user decides to print, we don't want the menu to be displayed on the screen
                    showMenu(false);
                    setTimeout(() => {
                      actionCallback();
                    }, 0);
                  }}
                />
              )}
            >
              {(_, showMenu) => (
                <NavIconBtn
                  isPrimary={false}
                  onClick={() => showMenu(true)}
                  icon={<Icons.Share />}
                />
              )}
            </TooltipWithHintAndMenu>,
          );
        }

        if (hasIntegrationEnabled) {
          quickActionControls.push(
            <TextTooltip
              key="integrations"
              placement="bottom"
              content={integrationTitles[activePmIntegration.type]}
              className="hint"
              distance={5}
            >
              <NavIconBtn
                isPrimary={false}
                onClick={() => dispatch(togglePmSidebar())}
                icon={<Icons.Integration />}
              />
            </TextTooltip>,
          );
        }
      }

      if (!((meFilter || canOnlyViewSelf) && viewType === 'people')) {
        if (quickActionControls.length) {
          quickActionControls.unshift(<Spacer size={8} />);
        }
      }

      if (logMyTimeView) {
        quickActionControls.push(<LoggedTimeHeader prefs={user.prefs} />);
      }

      navCtx.registerQuickActionCtrls(quickActionControls);
      navCtx.registerLeftControls(leftControls);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      hasIntegrationEnabled,
      meFilter,
      logTimeView,
      logMyTimeView,
      canOnlyViewSelf,
      visibleRangeRef.current,
      filters,
      isMobile,
      user.prefs,
    ],
  );

  useEffect(
    () => {
      return () => {
        navCtx.registerQuickActionCtrls([]);
        navCtx.registerLeftControls([]);
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
}
