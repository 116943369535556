import { userAgent } from '@float/libs/web/detect';

export const download = (uri: string, name: string) => {
  if (userAgent !== 'ie') {
    const link = document.createElement('a');

    link.download = name;
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } else {
    location.replace(uri);
  }
};
