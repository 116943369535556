import { FloatApp, FloatAppPlatform } from '@float/constants/app';
import { config } from '@float/libs/config';
import { FloatAppConfigClientAdapter } from '@float/libs/config/Config.types';
import { getCurrentHostname, getEnvironment } from '@float/web/lib/environment';
import {
  getIsCurrentPageASharedLink,
  getScheduleDensityFromSharedLink,
  getSharedPageLinkToken,
} from '@float/web/lib/sharedLinkView';

import { getBuildMode, getSocketServer } from './config.helpers';

const environment = getEnvironment();
const currentHostname = getCurrentHostname();

// @test-export
export class WebClientAdapter implements FloatAppConfigClientAdapter {
  api = {
    apiHostname: '',
    hostname: '/api',
    preventRelativeHostname: false,
    socketPath: '/_sockjs',
    socketServer: getSocketServer(environment),
    type: 'local',
    version: 'f1',
  };

  app = FloatApp.Web;

  environment = environment;

  events = { noSession: new CustomEvent('noSession') };

  fixedHourHeight = null;

  get isSharedView() {
    return getIsCurrentPageASharedLink();
  }

  get sharedViewToken() {
    return getSharedPageLinkToken();
  }

  get sharedViewScheduleDensity() {
    return getScheduleDensityFromSharedLink();
  }

  isFeature = /feature.*\.develop\.float\.com/.test(currentHostname);

  buildMode = getBuildMode(currentHostname);

  isNativeTimerApp = false;

  locale = navigator.language ?? 'en-US';

  platform = FloatAppPlatform.Web;

  reports = {
    get domain() {
      return window.location.origin;
    },
    getLegacyPath(endpoint: string) {
      return `/svc/ra/reports/${endpoint}`;
    },
    getPath(endpoint: string) {
      return `/svc/ra/v2/dashboard/${endpoint}`;
    },
  };

  requestClientId = null;

  shouldUseRefreshToken = false;
}

config.initialize(new WebClientAdapter());
