import React, {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { t, Trans } from '@lingui/macro';
import { Transition, TransitionRef } from 'TransitionManager';

import { trackEvent } from '@float/common/lib/analytics';
import { trackCompanyData } from '@float/common/lib/intercom';
import { getUser } from '@float/common/selectors/currentUser';
import { useAppSelectorStrict } from '@float/common/store';
import { FeatureFlag, featureFlags } from '@float/libs/featureFlags';
import { AlertBar } from '@float/ui/components/AlertBar/AlertBar';
import { Button } from '@float/ui/components/Button';
import { useRadio } from '@float/ui/deprecated';
import RadioButton from '@float/ui/deprecated/Earhart/Buttons/RadioButton';
import { IconArrowRight } from '@float/ui/icons/essentials/IconArrowRight';
import { IconWarningTriangle } from '@float/ui/icons/essentials/IconWarningTriangle';
import { AnimatePopUp } from '@float/ui/primitives/Animate/AnimatePopUp/AnimatePopUp';
import { OnboardingPrompt } from '@float/web/store/onboardingManager/types';

import { getGetToKnownYouConfig } from './config';
import { useCreateExampleData } from './hooks/useCreateExampleData';
import { GetToKnownYouDataType, GetToKnownYouOption } from './types';

import * as commonStyles from '../../styles.css';
import * as styles from './styles.css';

const withDefault = (
  data: GetToKnownYouDataType,
  dataKey: keyof GetToKnownYouDataType,
  previousState: OnboardingPrompt['previousState'],
) => {
  if (previousState) {
    return data[dataKey].map((item) => {
      if (item.data === previousState[dataKey]) {
        return { ...item, default: true };
      }

      return { ...item, default: false };
    });
  }

  return data[dataKey];
};

export const GetToKnowYou = forwardRef<TransitionRef, OnboardingPrompt>(
  (props, ref) => {
    const { transitionId, onClickNext, previousState } = props;

    const user = useAppSelectorStrict(getUser);

    const contentRef = useRef(null);

    const [ready, setReady] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const { createExampleData } = useCreateExampleData();

    const config = useMemo(getGetToKnownYouConfig, []);

    const getTeamSizeData = useMemo(() => {
      return withDefault(config, 'teamSize', previousState);
    }, [config, previousState]);

    const getSupportEffortData = useMemo(() => {
      return withDefault(config, 'supportEffort', previousState);
    }, [config, previousState]);

    const [teamSize, teamSizeProps] = useRadio(getTeamSizeData);
    const [supportEffort, supportEffortProps] = useRadio(getSupportEffortData);

    // Interaction handlers

    const goNext = useCallback(() => {
      if (onClickNext) {
        // track example data selected option
        // https://linear.app/float-com/issue/ACT-266
        if (featureFlags.isFeatureEnabled(FeatureFlag.OnboardingExampleData)) {
          trackEvent('Onboarding', {
            experiment: 'Example Data v1',
            onboarding_support: supportEffort.data,
          });
        }

        onClickNext({
          teamSize: teamSize.data,
          supportEffort: supportEffort.data,
        });
      }
    }, [onClickNext, teamSize, supportEffort]);

    const onClickNextHandler = useCallback(async () => {
      trackEvent('signedUp', {
        analyticsID: user.admin_id + '-' + user.cid,
        opportunitySize: teamSize.data,
      });

      trackCompanyData('Opportunity size', teamSize.data, user.cid);
      trackCompanyData('Support required', supportEffort.data, user.cid);

      setError('');
      setLoading(true);

      createExampleData(supportEffort.data).then(goNext).catch(showError);
    }, [
      goNext,
      createExampleData,
      setError,
      teamSize,
      supportEffort,
      user.admin_id,
      user.cid,
    ]);

    const showError = () => {
      setError(t`An error occurred saving data. Please try again.`);
      setLoading(false);
    };

    // Effects

    useEffect(() => {
      const ready = !!teamSize && !!supportEffort;

      setReady(ready);
      setError('');
    }, [teamSize, supportEffort]);

    return (
      <Transition ref={ref} id={transitionId}>
        <div className={commonStyles.content} ref={contentRef}>
          <h1 className={commonStyles.h1}>
            <Trans>Tell us your team's needs</Trans>
          </h1>

          <div className={commonStyles.question}>
            <h2 className={commonStyles.h2}>
              <Trans>How many people will you be scheduling?</Trans>
            </h2>

            <div className={styles.options}>
              {teamSizeProps.options.map(
                (option: GetToKnownYouOption & { value: number }) => {
                  const { label, desc, value } = option;

                  const isSelected = value === teamSizeProps.value;

                  return (
                    <RadioButton
                      // @ts-expect-error RadioButton is not typed
                      className={styles.radioButton}
                      key={value}
                      label={label}
                      name="teamSize"
                      onChange={teamSizeProps.onChange}
                      selected={isSelected}
                      subLabel={desc}
                      tabIndex={0}
                      value={value}
                    />
                  );
                },
              )}
            </div>
          </div>

          <div className={commonStyles.question}>
            <h2 className={commonStyles.h2}>
              <Trans>How would you like to get started?</Trans>
            </h2>

            <div className={styles.options}>
              {supportEffortProps.options.map(
                (option: GetToKnownYouOption & { value: number }) => {
                  const { label, desc, value } = option;

                  const isSelected = value === supportEffortProps.value;

                  return (
                    <RadioButton
                      // @ts-expect-error RadioButton is not typed
                      className={styles.radioButton}
                      key={value}
                      label={label}
                      name="supportEffort"
                      onChange={supportEffortProps.onChange}
                      selected={isSelected}
                      subLabel={desc}
                      tabIndex={0}
                      value={value}
                    />
                  );
                },
              )}
            </div>
          </div>

          <Button
            disabled={!ready || loading}
            iconEnd={IconArrowRight}
            isLoading={loading}
            onClick={onClickNextHandler}
            size="medium"
          >
            <Trans>Next</Trans>
          </Button>

          {error && (
            <AnimatePopUp>
              <AlertBar
                className={styles.error}
                message={error}
                icon={IconWarningTriangle}
              />
            </AnimatePopUp>
          )}
        </div>
      </Transition>
    );
  },
);
