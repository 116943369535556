import React from 'react';
import { t } from '@lingui/macro';
import { filter, isEmpty, isUndefined, map, uniq } from 'lodash';
import styled from 'styled-components';

import { isAmountEmpty } from '@float/common/lib/budget';
import { userCanSeeBudgets } from '@float/common/selectors/projects';
import {
  MONETARY_BUDGET_TYPES,
  NON_MONETARY_BUDGET_TYPES,
} from '@float/constants/budgets';
import { moment } from '@float/libs/moment';
import { BudgetPriority } from '@float/types';
import {
  bindVal,
  ColorPicker,
  CurrencyInput,
  DatePicker,
  Input,
  ModalTopGreySection,
  ReadOnlyField,
  RichText,
  ToggleButton,
  ToggleGroup,
  VirtualSelect,
} from '@float/ui/deprecated';
import { TextButton } from '@float/ui/deprecated/Earhart/Buttons';
import { StatusDropdown } from '@float/web/components/panelViews/Project/components/StatusDropdown';

import { TagsMultiselect } from './components/TagsMultiselect';
import { getColoredTags } from './helpers/getColoredTags';

export const ToggleGroupWrapper = styled.div`
  display: flex;
  margin-bottom: 22px;

  & > div {
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }
  }
`;

export const BudgetRow = styled.div`
  display: flex;
`;

export const budgetOptions = [
  {
    value: 0,
    label: 'No budget',
    budget_type: 0,
    budget_priority: BudgetPriority.Project,
  },
  {
    value: 1,
    label: 'Hours by project',
    budget_type: 1,
    budget_priority: BudgetPriority.Project,
  },
  {
    value: 1.1,
    label: 'Hours by phase',
    budget_type: 1,
    budget_priority: BudgetPriority.Phase,
  },
  {
    value: 2,
    label: 'Fee by project',
    budget_type: 2,
    budget_priority: BudgetPriority.Project,
  },
  {
    value: 2.1,
    label: 'Fee by phase',
    budget_type: 2,
    budget_priority: BudgetPriority.Phase,
  },
  {
    value: 3,
    label: 'Hourly fee',
    budget_type: 3,
    budget_priority: BudgetPriority.Project,
  },
];

// Must be a function to allow for Lingui translations
const getRateOptions = () => [
  { value: 0, label: t`Different hourly rates per team member` },
  { value: 1, label: t`Same hourly rate for everyone` },
];

export const rateOptions = getRateOptions();

function handleAddTag(tag) {
  this.setState((ps) => ({
    formErrors: {
      ...ps.formErrors,
      project_tags: [],
    },
    form: {
      ...ps.form,
      tags: [...ps.form.tags, tag.value],
    },
  }));
}

function handleRemoveTag(tag) {
  this.setState((ps) => ({
    formErrors: {
      ...ps.formErrors,
      project_tags: [],
    },
    form: {
      ...ps.form,
      tags: ps.form.tags.filter((t) => t !== tag.label),
    },
  }));
}

function onDateChange(name, momentDate) {
  const date = momentDate ? momentDate.format('YYYY-MM-DD') : null;
  const { start_date, end_date } = this.state.form;
  const newState = { [name]: date };

  // Set start date to end date if end date was before start
  if (name === 'end_date') {
    if (date && start_date && start_date > date) {
      newState.start_date = date;
    }
  }

  // Set end date to start date if start date was after end
  if (name === 'start_date') {
    if (date && end_date && end_date < date) {
      newState.end_date = date;
    }
  }

  this.setState((prev) => {
    return { form: { ...prev.form, ...newState } };
  });
}

const formatDate = (momentDate) =>
  momentDate ? momentDate.format('YYYY-MM-DD') : null;

function onDuplicateDateChange(name, momentDate) {
  const date = formatDate(momentDate);
  const { durationInDays: duration } = this.initialProject;
  const newState = { [name]: date };

  // Keep duration constant
  if (name === 'end_date') {
    newState.start_date = formatDate(momentDate.subtract(duration, 'days'));
  } else if (name === 'start_date') {
    newState.end_date = formatDate(momentDate.add(duration, 'days'));
  }

  this.setState((prev) => {
    return { form: { ...prev.form, ...newState } };
  });
}

const DateContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 150px;
  margin-right: 20px;

  input {
    font-size: 18px !important;
    width: 100%;
  }
`;

export default function InfoFragment() {
  const { currentUser, projectTagByLabel } = this.props;
  const { form, formErrors } = this.state;
  const duplicating = this.isDuplicating();
  const dateChangeFn = duplicating ? onDuplicateDateChange : onDateChange;

  const start_date = moment(`${form.start_date} 00:00:00`);
  const end_date = moment(`${form.end_date} 00:00:00`);

  const recentColors = uniq(
    map(this.props.projectsSortedByCreation, (p) => p.color),
  );

  const clientColors = uniq(
    filter(
      this.props.projectsSortedByCreation,
      (p) => p.client_id == form.client_id,
    ).map((p) => p.color),
  );

  const isBudgetPerPhase = form.budget_priority === BudgetPriority.Phase;

  let budgetOptionsForUser = userCanSeeBudgets(currentUser)
    ? budgetOptions
    : budgetOptions.filter((bo) =>
        NON_MONETARY_BUDGET_TYPES.includes(bo.budget_type),
      );

  if (this.isPhase) {
    budgetOptionsForUser = budgetOptionsForUser.filter(
      (o) => o.budget_priority === BudgetPriority.Phase || !o.budget_type,
    );
  } else if (!this.hasPhase() && !isBudgetPerPhase) {
    budgetOptionsForUser = budgetOptionsForUser.filter(
      (o) => o.budget_priority !== BudgetPriority.Phase,
    );
  }

  const monetaryBudget = MONETARY_BUDGET_TYPES.includes(form.budget_type);
  const renderRateRow =
    (!this.isPhase && monetaryBudget && !isBudgetPerPhase) ||
    (this.isPhase && form.budget_type === 2) ||
    null;

  const budgetRateType = form.default_hourly_rate === null ? 0 : 1;

  const budgetRateTypeSecondaryText =
    budgetRateType === 0 && !duplicating ? (
      <>
        Based on{' '}
        <TextButton
          appearance="flay-underline"
          onClick={() => this.setState({ activeTab: 'team' })}
        >
          each person's default hourly rate
        </TextButton>
      </>
    ) : undefined;

  const canSeeBudgets = userCanSeeBudgets(currentUser);
  const renderBudgets =
    canSeeBudgets || NON_MONETARY_BUDGET_TYPES.includes(form.budget_type);

  const isCalculatedBudget = this.isPhase && this.project.budget_type;

  const readOnlyBudgetLabel =
    isCalculatedBudget &&
    budgetOptions.find(
      (option) =>
        option.budget_type === this.project.budget_type &&
        option.budget_priority === this.project.budget_priority,
    )?.label;

  return (
    <React.Fragment>
      {(this.isPhase ||
        (duplicating &&
          !this.isTemplate() &&
          !isUndefined(this.initialProject?.durationInDays))) && (
        <ModalTopGreySection style={{ display: 'flex' }}>
          <DateContainer>
            <DatePicker
              label="From"
              value={start_date}
              onChange={dateChangeFn.bind(this, 'start_date')}
              disableDayFn={this.startDateRangeLimit}
            />
          </DateContainer>
          <DateContainer style={{ marginRight: 0 }}>
            <DatePicker
              label="To"
              value={end_date}
              onChange={dateChangeFn.bind(this, 'end_date')}
              disableDayFn={this.endDateRangeLimit}
            />
          </DateContainer>
        </ModalTopGreySection>
      )}

      {duplicating && (
        <StatusDropdown
          {...bindVal(this, 'status')}
          allowDraftOption={this.shouldAllowDraftOption()}
          style={{ marginBottom: 22 }}
        />
      )}

      {this.isPhase ? (
        <ReadOnlyField
          label="Project"
          type="text"
          value={this.getProjectName()}
          wrapperStyle={{ marginBottom: 21 }}
        />
      ) : (
        <VirtualSelect
          label="Client"
          style={{ marginBottom: 22 }}
          creatable
          autoSelectOnBlur
          placeholder="No Client"
          clearInputOnDropdownOpen={false}
          visibleItems={6}
          options={this.props.clientOptions}
          {...bindVal(this, 'client_id')}
        />
      )}

      <ColorPicker
        style={{ marginBottom: 22 }}
        clientColors={clientColors}
        recentColors={recentColors}
        {...bindVal(this, 'color')}
      />

      <RichText
        label="Notes"
        {...bindVal(this, this.isPhase ? 'notes' : 'description')}
        valueMeta={this.state.form.notes_meta}
        maxLength={1500}
        mentionsEnabled={!this.isTemplate()}
        style={{ marginBottom: 22 }}
      />

      {this.isPhase ? (
        !isEmpty(this.project.tags) && (
          <ReadOnlyField
            label="Tags"
            type="tags"
            value={getColoredTags(this.project.tags, projectTagByLabel)}
            wrapperStyle={{ marginBottom: 21 }}
          />
        )
      ) : (
        <TagsMultiselect
          values={form.tags}
          errors={formErrors.project_tags}
          onAdd={handleAddTag.bind(this)}
          onRemove={handleRemoveTag.bind(this)}
        />
      )}

      <ToggleGroupWrapper>
        <ToggleGroup
          appearance="stitched"
          disableUnselect
          {...bindVal(this, 'non_billable')}
        >
          <ToggleButton value={false}>Billable</ToggleButton>
          <ToggleButton value={true}>Non-billable</ToggleButton>
        </ToggleGroup>

        {!duplicating && (
          <ToggleGroup {...bindVal(this, 'tentative')}>
            <ToggleButton value={true}>Tentative</ToggleButton>
          </ToggleGroup>
        )}
      </ToggleGroupWrapper>

      {renderBudgets && (
        <>
          <BudgetRow style={{ marginBottom: 22 }}>
            <div style={{ width: 340, marginRight: 40 }}>
              {isCalculatedBudget ? (
                <ReadOnlyField
                  label="Project budget"
                  type="text"
                  value={readOnlyBudgetLabel}
                />
              ) : (
                <VirtualSelect
                  label={this.isPhase ? 'Project budget' : 'Budget'}
                  nonNullable
                  visibleItems={6}
                  options={budgetOptionsForUser}
                  value={form.budget_type + (isBudgetPerPhase ? 0.1 : 0)}
                  onChange={(opt) => {
                    this.setState((ps) => ({
                      form: {
                        ...ps.form,
                        budget_type: opt.budget_type || 0,
                        budget_priority: opt.budget_priority || 0,
                        budget_total: '0',
                        default_hourly_rate: null,
                      },
                      formErrors: this.resetBudgetFormErrors(ps),
                    }));
                  }}
                />
              )}
            </div>
            {!this.isPhase && isBudgetPerPhase ? (
              <>
                {form.budget_type === 1 && (
                  <ReadOnlyField
                    label="Total"
                    type="text"
                    value={this.getPhaseBudgetSum()}
                  />
                )}

                {form.budget_type === 2 && canSeeBudgets && (
                  <ReadOnlyField
                    label="Total"
                    type="text"
                    value={this.getPhaseBudgetSum()}
                    currentUser={currentUser}
                  />
                )}
              </>
            ) : (
              (!this.isPhase || isBudgetPerPhase) && (
                <>
                  {form.budget_type === 1 && (
                    <div style={{ width: 120 }}>
                      <Input
                        label="Hours"
                        placeholder="0"
                        {...bindVal(
                          this,
                          'budget_total',
                          undefined,
                          Input.validators.number(9),
                        )}
                        value={
                          isAmountEmpty(form.budget_total) ||
                          (!this.isPhase && form.budget_total == '0')
                            ? ''
                            : form.budget_total
                        }
                      />
                    </div>
                  )}
                  {form.budget_type === 2 && (
                    <div style={{ width: 120 }}>
                      <CurrencyInput
                        label="Total"
                        currentUser={currentUser}
                        allowZero={this.isPhase}
                        {...bindVal(
                          this,
                          'budget_total',
                          undefined,
                          Input.validators.number(9),
                        )}
                      />
                    </div>
                  )}
                </>
              )
            )}
          </BudgetRow>

          {renderRateRow && (
            <BudgetRow>
              <div style={{ width: 340 }}>
                <VirtualSelect
                  label="Rate"
                  nonNullable
                  visibleItems={6}
                  options={rateOptions}
                  value={budgetRateType}
                  secondaryText={budgetRateTypeSecondaryText}
                  onChange={({ value }) => {
                    this.setState((ps) => ({
                      form: {
                        ...ps.form,
                        default_hourly_rate: value === 0 ? null : '0',
                      },
                      formErrors: {
                        ...ps.formErrors,
                        people_rates: [],
                        default_hourly_rate: [],
                      },
                    }));
                  }}
                />
              </div>

              {budgetRateType === 1 && (
                <div style={{ width: 120, marginLeft: 40 }}>
                  <CurrencyInput
                    currentUser={currentUser}
                    label="Per Hour"
                    {...bindVal(
                      this,
                      'default_hourly_rate',
                      undefined,
                      Input.validators.number(6),
                    )}
                  />
                </div>
              )}
            </BudgetRow>
          )}
        </>
      )}
    </React.Fragment>
  );
}
