import React, { MouseEventHandler, useEffect } from 'react';

import { NavIconBtn } from '@float/common/components/NavIconBtn';
import useMedia from '@float/libs/web/hooks/useMedia';
import { EH, Icons } from '@float/ui/deprecated';
import { useNavUpdater } from 'components/Nav/NavContext';

interface ProjectQuickActionsProps {
  showManageTemplatesModal?: MouseEventHandler;
  showImportProjectModal?: MouseEventHandler;
}

export const ProjectQuickActions: React.FC<ProjectQuickActionsProps> = ({
  showManageTemplatesModal,
  showImportProjectModal,
}) => {
  const navCtx = useNavUpdater();
  const isMobile = useMedia() === 'small';

  useEffect(() => {
    const quickActionControls = [];

    if (!isMobile) {
      quickActionControls.push(
        <NavIconBtn
          isPrimary={false}
          iconLeft={<Icons.CopyRound size={'20'} />}
          onClick={showManageTemplatesModal}
          style={{
            minWidth: 160,
          }}
        >
          Manage templates
        </NavIconBtn>,
      );

      quickActionControls.push(
        <NavIconBtn
          isPrimary={false}
          iconLeft={<EH.Icons.IconImport size={20} />}
          onClick={showImportProjectModal}
        >
          Import
        </NavIconBtn>,
      );
    }

    navCtx.registerQuickActionCtrls(quickActionControls);
    return () => {
      navCtx.registerQuickActionCtrls([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};
