import React from 'react';

import { SVGIcon, SVGIconProps } from '../../primitives/SVGIcon';

const IconProjectTentative = (props: SVGIconProps) => {
  return (
    <SVGIcon {...props}>
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M18.74793,16.14404l-6.49973,3.71413c-.1538.08789-.34261.08789-.49641,0l-6.49973-3.71413c-.15587-.08907-.25207-.25483-.25207-.43436v-7.41936c0-.17953.09619-.34529.25207-.43436l6.49973-3.71413c.1538-.08789.34261-.08789.49641,0l6.49973,3.71413c.15587.08907.25207.25483.25207.43436v7.41936c0,.17953-.09619.34529-.25207.43436Z"
      />
    </SVGIcon>
  );
};

export { IconProjectTentative };
