import { ComponentProps } from 'react';

import { canSeeTimeoffBalance as canSeeTimeoffBalanceFn } from '@float/common/lib/rights';
import { Timeoff } from '@float/types/timeoff';
import { TimeoffType } from '@float/types/timeoffType';
import { getAssignedElem } from '@float/web/components/taskModals/dom/assignedElem';
import { getNotesElem } from '@float/web/components/taskModals/dom/notesElem';
import { getTimeoffStatusToggleButtons } from '@float/web/components/taskModals/dom/statusToggleButtons';

import { IEditTaskModal } from '../../../EditTaskModal.types';
import { TimeoffTabContent } from '../TimeoffTabContent';

/**
 * This helper function generates the properties for the AllocationTimeoffTab
 * component. Since the EditTaskModal has not yet been migrated to TypeScript,
 * the properties declaration is kept separate to ensure they are type-checked.
 *
 * @param {IEditTaskModal} self - The context object that contains the interface
 * of the EditTaskModal.
 * @returns {ComponentProps<typeof TimeoffTabContent>} The properties for the
 * AllocationTimeoffTab component.
 */

export const getTimeoffTabContentProps = (
  self: IEditTaskModal,
): ComponentProps<typeof TimeoffTabContent> => {
  const { props, state } = self;

  const isReadOnly = state.readOnly;
  const personId =
    state.peopleIds.length === 1 ? state.peopleIds[0] : undefined;
  const person = personId ? props.peopleMap[personId] : undefined;
  const timeoff = state.task as Timeoff;
  const canSeeTimeoffBalance =
    state.peopleIds.length === 1 &&
    canSeeTimeoffBalanceFn(person, props.user) &&
    !isReadOnly;
  const currentTimeoffType = props.timeoffTypesMap[state.timeoffTypeId];
  const isRequestMode = self.isRequestMode();

  const onChange = (timeoffType: TimeoffType) => {
    self.setState({
      timeoffTypeId: timeoffType.timeoff_type_id,
      timeoffTypeName: timeoffType.timeoff_type_name,
      timeoffType: timeoffType,
    });
  };

  return {
    canSeeTimeoffBalance,
    disableAnimations: self.disableAnimations,
    enableAnimations: self.enableAnimations,
    endDate: state.endDate,
    getAssignedElem: () => getAssignedElem(self),
    getAssociatedChangesForEntity: props.getAssociatedChangesForEntity,
    getExportedTaskCopiedFromState: self.getExportedTaskCopiedFromState,
    getNotesElem: () => getNotesElem(self),
    getTimeoffStatusToggleButtons: () => getTimeoffStatusToggleButtons(self),
    hoursPd: state.hoursPd,
    isApproveRejectMode: self.isApproveRejectMode(),
    isLayoutAnimationEnabled: self.isLayoutAnimationEnabled('preserve-aspect'),
    isReadOnly,
    isRequestMode,
    isStatusMode: self.isStatusMode(),
    onChange,
    personId,
    personStartDate: person?.start_date,
    repeatEnd: state.repeatEnd,
    repeatState: state.repeatState,
    startDate: state.startDate,
    timeoff,
    timeoffType: currentTimeoffType,
    timeoffTypeId: state.timeoffTypeId,
    timeoffTypes: props.timeoffTypes,
  };
};
