import { FloatApp } from '@float/constants/app';
import {
  FLOAT_PRODUCTION_AND_STAGING_DEPLOYMENT_ENVIRONMENTS,
  FloatBuildMode,
} from '@float/constants/environment';
import { logger } from '@float/libs/logger';

import {
  FloatAppConfigClientAdapter,
  SerializedFloatAppConfig,
} from './Config.types';

export class FloatAppConfig {
  private _clientAdapter: FloatAppConfigClientAdapter | undefined;

  private get clientAdapter(): FloatAppConfigClientAdapter {
    if (!this._clientAdapter) {
      throw new Error(
        'The Config instance is not initialized, you need to call `initialize` with the platform appropriate ConfigClientAdapter first',
      );
    }

    return this._clientAdapter;
  }

  public async initialize(clientAdapter: FloatAppConfigClientAdapter) {
    if (this._clientAdapter) {
      logger.warn('The Config instance has already been initialized');
      return;
    }

    this._clientAdapter = clientAdapter;
  }

  public get isMobileApp() {
    return this.app === FloatApp.Mobile;
  }

  public get isNativeTimerApp() {
    return this.app === FloatApp.DesktopTimer;
  }

  public get isSharedView() {
    return this.clientAdapter.isSharedView;
  }

  public get isStagingOrProduction() {
    return FLOAT_PRODUCTION_AND_STAGING_DEPLOYMENT_ENVIRONMENTS.includes(
      this.environment,
    );
  }

  public get isDevBuildMode() {
    return this.buildMode === FloatBuildMode.Development;
  }

  public get isProductionBuildMode() {
    return this.buildMode === FloatBuildMode.Production;
  }

  public get api() {
    return this.clientAdapter.api;
  }
  public get app() {
    return this.clientAdapter.app;
  }
  public get buildMode() {
    return this.clientAdapter.buildMode;
  }
  public get environment() {
    return this.clientAdapter.environment;
  }
  public get fixedHourHeight() {
    return this.clientAdapter.fixedHourHeight;
  }
  public get isFeature() {
    return this.clientAdapter.isFeature;
  }
  public get locale() {
    return this.clientAdapter.locale;
  }
  public get platform() {
    return this.clientAdapter.platform;
  }
  public get reports() {
    return this.clientAdapter.reports;
  }
  public get requestClientId() {
    return this.clientAdapter.requestClientId;
  }
  public get sharedViewToken() {
    return this.clientAdapter.sharedViewToken;
  }

  public get sharedViewScheduleDensity() {
    return this.clientAdapter.sharedViewScheduleDensity;
  }

  public serialize(): SerializedFloatAppConfig {
    return {
      api: this.clientAdapter.api,
      app: this.clientAdapter.app,
      isSharedView: this.clientAdapter.isSharedView,
      buildMode: this.clientAdapter.buildMode,
      environment: this.clientAdapter.environment,
      fixedHourHeight: this.clientAdapter.fixedHourHeight,
      isFeature: this.clientAdapter.isFeature,
      locale: this.clientAdapter.locale,
      platform: this.clientAdapter.platform,
      reports: {
        domain: this.clientAdapter.reports.domain,
      },
      shouldUseRefreshToken: this.clientAdapter.shouldUseRefreshToken,
      requestClientId: this.clientAdapter.requestClientId,
      sharedViewToken: this.clientAdapter.sharedViewToken,
      sharedViewScheduleDensity: this.clientAdapter.sharedViewScheduleDensity,
    };
  }
}
