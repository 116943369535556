import React from 'react';

import { SVGIcon, SVGIconProps } from '../../primitives/SVGIcon';

const IconProjectDraft = (props: SVGIconProps) => {
  return (
    <SVGIcon {...props}>
      <path
        fill="#000"
        d="M18.06934,17.39551l-.74414-1.30273.9248-.52832v-1.06445h1.5v1.5c0,.26953-.14453.51758-.37793.65137l-1.30273.74414Z"
      />
      <rect
        fill="#000"
        x="14.23367"
        y="17.24986"
        width="2.53169"
        height="1.50029"
        transform="translate(-6.88705 10.05792) rotate(-29.73563)"
      />
      <path
        fill="#000"
        d="M12,20.75c-.12842,0-.25684-.0332-.37207-.09863l-1.30225-.74414.74414-1.30273.93018.53125.93066-.53125.74414,1.30273-1.30273.74414c-.11523.06543-.24414.09863-.37207.09863Z"
      />
      <rect
        fill="#000"
        x="7.74961"
        y="16.73437"
        width="1.50029"
        height="2.53127"
        transform="translate(-11.34568 16.44935) rotate(-60.25512)"
      />
      <path
        fill="#000"
        d="M5.93018,17.39551l-1.30225-.74414c-.23389-.13379-.37793-.38184-.37793-.65137v-1.5h1.5v1.06445l.92432.52832-.74414,1.30273Z"
      />
      <rect fill="#000" x="4.25" y="10.75" width="1.5" height="2.5" />
      <path
        fill="#000"
        d="M5.75,9.5h-1.5v-1.5c0-.26904.14404-.51758.37793-.65137l1.30225-.74414.74414,1.30273-.92432.52783v1.06494Z"
      />
      <rect
        fill="#000"
        x="7.23461"
        y="5.24986"
        width="2.53127"
        height="1.50029"
        transform="translate(-1.85671 5.00617) rotate(-29.73563)"
      />
      <path
        fill="#000"
        d="M12.93066,5.39551l-.93066-.53174-.93018.53174-.74414-1.30273,1.30225-.74414c.23096-.13086.51367-.13135.74414,0l1.30273.74414-.74414,1.30273Z"
      />
      <rect
        fill="#000"
        x="14.74986"
        y="4.73458"
        width="1.50029"
        height="2.53084"
        transform="translate(2.59938 16.4797) rotate(-60.24963)"
      />
      <path
        fill="#000"
        d="M19.75,9.5h-1.5v-1.06445l-.9248-.52832.74414-1.30273,1.30273.74414c.2334.13379.37793.38232.37793.65137v1.5Z"
      />
      <rect fill="#000" x="18.25" y="10.75" width="1.5" height="2.5" />
    </SVGIcon>
  );
};

export { IconProjectDraft };
