import React from 'react';

import { SVGIcon, SVGIconProps } from '../../primitives/SVGIcon';

const IconProjectPlan = (props: SVGIconProps) => {
  return (
    <SVGIcon {...props}>
      <path
        d="M4.75 19.25H19.25"
        stroke="#000"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="10.25"
        y="12"
        width="9"
        height="3.5"
        rx="1"
        stroke="#000"
        strokeWidth="1.5"
      />
      <rect
        x="4.75"
        y="4.75"
        width="9"
        height="3.5"
        rx="1"
        stroke="#000"
        strokeWidth="1.5"
      />
    </SVGIcon>
  );
};

export { IconProjectPlan };
