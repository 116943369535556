import React from 'react';

import { SVGIcon, SVGIconProps } from '../../primitives/SVGIcon';

const IconProjectConfirmed = (props: SVGIconProps) => {
  return (
    <SVGIcon {...props}>
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M18.74928,16.14327l-6.5024,3.71566c-.15298.08742-.34078.08742-.49375,0l-6.5024-3.71566c-.15504-.08859-.25072-.25347-.25072-.43204v-7.42247c0-.17857.09568-.34344.25072-.43204l6.5024-3.71566c.15298-.08742.34078-.08742.49375,0l6.5024,3.71566c.15504.08859.25072.25347.25072.43204v7.42247c0,.17857-.09568.34344-.25072.43204Z"
      />
      <path
        fill="#000"
        d="M16.36054,14.80456l-4.11199,2.37406c-.1538.0888-.3433.0888-.4971,0l-4.11199-2.37406c-.1538-.0888-.24855-.25291-.24855-.4305v-4.74811c0-.1776.09475-.3417.24855-.4305l4.11199-2.37406c.1538-.0888.3433-.0888.4971,0l4.11199,2.37406c.1538.0888.24855.25291.24855.4305v4.74811c0,.1776-.09475.3417-.24855.4305Z"
      />
    </SVGIcon>
  );
};

export { IconProjectConfirmed };
