import React, { forwardRef, useCallback, useMemo } from 'react';
import { Trans } from '@lingui/macro';
import { LayoutProps, motion } from 'framer-motion';

import { useAppSelectorStrict } from '@float/common/store';
import { formatToFloatDate } from '@float/libs/dates';
import { useOnMount } from '@float/libs/hooks/useOnMount';
import { multiplyOperation } from '@float/libs/utils/floats';
import { TimeString } from '@float/types/datesManager';
import { RepeatState } from '@float/types/repeatState';
import { Timeoff } from '@float/types/timeoff';
import { TextButton } from '@float/ui/deprecated/Earhart/Buttons';
import { getPeopleMapRaw, getTimeoffTypesMap } from '@float/web/selectors';

import {
  InputAllocationDateRange,
  InputAllocationDateRangeProps,
} from '../../components/InputAllocationDateRange/InputAllocationDateRange';
import {
  InputAllocationHours,
  InputAllocationHoursProps,
} from '../../components/InputAllocationHours/InputAllocationHours';
import { InputAllocationRepeat } from '../../components/InputAllocationRepeat';
import {
  InputAllocationTimeRange,
  InputAllocationTimeRangeProps,
} from '../../components/InputAllocationTimeRange/InputAllocationTimeRange';
import { TimeoffApproveRejectHeader } from '../../components/TimeoffApproveRejectHeader/TimeoffApproveRejectHeader';
import { getIsSpecificTimeSet } from '../../EditTaskModal.helpers';
import { AllocationTimeSectionPayload } from '../../EditTaskModal.types';
import { getAllocationCorrectedHoursUsingHoursPerDay } from '../../helpers/getAllocationCorrectedHours';
import { useAllocationDaysHelpers } from '../../hooks/useAllocationDaysHelpers';
import { useAllocationRepeatControls } from '../../hooks/useAllocationRepeatControls';
import { useAllocationTimeToggle } from '../../hooks/useAllocationTimeToggle';
import { useIsTotalHoursInputSubdued } from '../../hooks/useIsTotalHoursInputSubdued';
import { getIsFullDay } from './TimeoffTimeSection.helpers';

import * as styles from '../../EditTaskModal.css';

export type TimeoffTimeSectionProps = {
  endDate: Date;
  hasRecurringExternalCalendar: boolean;
  hoursPerDay: number;
  hoursTotal: number;
  is24HoursTimeFormat: boolean;
  isApproveRejectMode: boolean;
  isIntegrationSyncLocked: boolean;
  isIntervalRepeatable: boolean;
  isReadOnly: boolean;
  layout: LayoutProps['layout'];
  onChange: (data: AllocationTimeSectionPayload) => void;
  peopleIds: number[];
  repeatState: RepeatState;
  repeatEndDate: Date | null;
  startDate: Date;
  startTime: TimeString | null;
  taskTimeoffId: number;
  timeoffId?: number;
  timeoff?: Timeoff;
};

export const TimeoffTimeSection = forwardRef<
  HTMLDivElement,
  TimeoffTimeSectionProps
>((props, ref) => {
  const {
    endDate,
    hasRecurringExternalCalendar,
    hoursPerDay,
    hoursTotal,
    is24HoursTimeFormat,
    isApproveRejectMode,
    isIntervalRepeatable,
    isIntegrationSyncLocked,
    isReadOnly,
    layout,
    onChange,
    peopleIds,
    repeatEndDate,
    repeatState,
    startDate,
    startTime,
    timeoffId,
    timeoff,
  } = props;

  const timeoffTypesMap = useAppSelectorStrict(getTimeoffTypesMap);
  const peopleMap = useAppSelectorStrict(getPeopleMapRaw);

  const {
    getIsWorkDay,
    getHasDifferentDailyWorkingHours,
    getNumberOfAllocationDays,
    getEndDateFromTotalHours,
    getMinimumWorkHoursInRange,
  } = useAllocationDaysHelpers({
    peopleIds,
    timeoffId,
  });

  const numberOfAllocationDays = useMemo(() => {
    return getNumberOfAllocationDays(startDate, endDate);
  }, [startDate, endDate, getNumberOfAllocationDays]);

  const minimumWorkHoursInRange = getMinimumWorkHoursInRange(
    peopleIds,
    formatToFloatDate(startDate),
    formatToFloatDate(endDate),
  );

  const isSpecificTime = getIsSpecificTimeSet(startTime);

  const minimumHoursPerDayValue = isSpecificTime ? 0.01 : 0.1;
  const maximumHoursPerDayValue = minimumWorkHoursInRange;

  const isFullDay = getIsFullDay({
    startTime,
    hoursPerDay,
    minimumWorkHoursInRange,
  });

  // The total hours might need a re-computation on mount
  useOnMount(() => {
    const { hoursPerDayCorrected, hoursTotalCorrected } =
      getAllocationCorrectedHoursUsingHoursPerDay({
        hoursPerDay,
        hoursTotal,
        numberOfAllocationDays,
        minimumHoursPerDayValue,
        maximumHoursPerDayValue,
      });

    onChange({
      hoursPerDay: hoursPerDayCorrected,
      hoursTotal: hoursTotalCorrected,
      isFullDay,
    });
  });

  const isTotalHoursInputSubdued = useIsTotalHoursInputSubdued(
    numberOfAllocationDays,
  );

  const hasDifferentDailyWorkingHours = useMemo(() => {
    return getHasDifferentDailyWorkingHours({
      startDate,
      endDate,
    });
  }, [startDate, endDate, getHasDifferentDailyWorkingHours]);

  const hasApproveRejectHeader =
    isApproveRejectMode &&
    typeof timeoffId !== 'undefined' &&
    typeof timeoff !== 'undefined';

  const getIsDateDisabled = useCallback(
    (date: Moment) => !getIsWorkDay(date.toDate()),
    [getIsWorkDay],
  );

  const handleSpecificTimeToggle = (payload: AllocationTimeSectionPayload) => {
    const isFullDay = getIsFullDay({
      startTime: payload.startTime,
      hoursPerDay: payload.hoursPerDay,
      minimumWorkHoursInRange,
    });

    onChange({
      ...payload,
      isFullDay,
    });
  };

  const {
    isAllocationByTime,
    isAllocationByHours,
    handleClickSetSpecificTimeButton,
    handleClickSetTotalHoursButton,
  } = useAllocationTimeToggle({
    startTime,
    hoursPerDay,
    numberOfAllocationDays,
    onChange: handleSpecificTimeToggle,
  });

  const handleHoursPerDayChange: InputAllocationHoursProps['onChangeHoursPerDay'] =
    (hoursPerDay) => {
      const isFullDay = getIsFullDay({
        hoursPerDay,
        minimumWorkHoursInRange,
      });

      onChange({
        hoursPerDay,
        isFullDay,
      });
    };

  const handleHoursTotalChange: InputAllocationHoursProps['onChangeHoursTotal'] =
    (hoursTotal) => {
      const endDate = getEndDateFromTotalHours({
        startDate,
        hoursPerDay,
        hoursTotal: hoursTotal || hoursPerDay,
      });

      const numberOfAllocationDays = getNumberOfAllocationDays(
        startDate,
        endDate,
      );

      const hoursPerDayRecomputed =
        typeof hoursTotal === 'number'
          ? hoursTotal / numberOfAllocationDays
          : 0;

      const isFullDay = getIsFullDay({
        hoursPerDay: hoursPerDayRecomputed,
        minimumWorkHoursInRange,
      });

      onChange({
        hoursTotal,
        endDate,
        isFullDay,
      });
    };

  const handleHoursPerDayBlur = () => {
    const { hoursPerDayCorrected, hoursTotalCorrected } =
      getAllocationCorrectedHoursUsingHoursPerDay({
        hoursPerDay,
        hoursTotal,
        numberOfAllocationDays,
        minimumHoursPerDayValue,
        maximumHoursPerDayValue,
      });

    const isFullDay = getIsFullDay({
      hoursPerDay: hoursPerDayCorrected,
      minimumWorkHoursInRange,
    });

    onChange({
      hoursPerDay: hoursPerDayCorrected,
      hoursTotal: hoursTotalCorrected,
      isFullDay,
    });
  };

  const handleHoursTotalBlur = () => {
    const { hoursPerDayCorrected, hoursTotalCorrected } =
      getAllocationCorrectedHoursUsingHoursPerDay({
        hoursPerDay: Math.min(hoursPerDay, hoursTotal),
        hoursTotal,
        numberOfAllocationDays,
        minimumHoursPerDayValue,
        maximumHoursPerDayValue,
      });

    const isFullDay = getIsFullDay({
      hoursPerDay: hoursPerDayCorrected,
      minimumWorkHoursInRange,
    });

    onChange({
      hoursTotal: hoursTotalCorrected,
      hoursPerDay: hoursPerDayCorrected,
      isFullDay,
    });
  };

  const handleTimeRangeChange: InputAllocationTimeRangeProps['onChange'] = (
    data,
  ) => {
    const { startTime, hoursPerDay } = data;

    const hoursTotal = multiplyOperation(hoursPerDay, numberOfAllocationDays);

    const isFullDay = getIsFullDay({
      startTime,
      hoursPerDay,
      minimumWorkHoursInRange,
    });

    onChange({
      startTime,
      hoursTotal,
      hoursPerDay,
      isFullDay,
    });
  };

  const handleDateRangeChange: InputAllocationDateRangeProps['onChange'] = (
    data,
  ) => {
    const { startDate, endDate } = data;

    const numberOfAllocationDays = getNumberOfAllocationDays(
      startDate,
      endDate,
    );

    const { hoursPerDayCorrected, hoursTotalCorrected } =
      getAllocationCorrectedHoursUsingHoursPerDay({
        hoursPerDay,
        hoursTotal,
        numberOfAllocationDays,
        minimumHoursPerDayValue,
        maximumHoursPerDayValue,
      });

    const isFullDay = getIsFullDay({
      startTime,
      hoursPerDay: hoursPerDayCorrected,
      minimumWorkHoursInRange,
    });

    onChange({
      startDate,
      endDate,
      hoursPerDay: hoursPerDayCorrected,
      hoursTotal: hoursTotalCorrected,
      isFullDay,
    });
  };

  const { repeatTimes, handleRepeatEndDateChange, handleRepeatIntervalChange } =
    useAllocationRepeatControls({
      startDate,
      repeatState,
      repeatEndDate,
      onChange,
    });

  return (
    <motion.div
      layout={layout}
      className={
        isReadOnly
          ? styles.timeSectionReadOnlyWrapper
          : styles.timeSectionWrapper
      }
      ref={ref}
    >
      {hasApproveRejectHeader && (
        <TimeoffApproveRejectHeader
          isReadOnly={isReadOnly}
          endDate={endDate}
          startDate={startDate}
          ignoreTimeoffId={timeoffId}
          repeatEndDate={repeatEndDate}
          repeatState={repeatState}
          hoursPd={hoursPerDay}
          timeoff={timeoff}
          timeoffType={timeoffTypesMap[timeoff?.timeoff_type_id]}
          personId={peopleIds[0]}
          personStartDate={peopleMap[peopleIds[0]]?.start_date}
        />
      )}
      {isAllocationByHours && (
        <div className={styles.timeSectionColumn}>
          <InputAllocationHours
            hoursPerDay={hoursPerDay}
            hoursTotal={hoursTotal}
            isReadOnly={isReadOnly}
            isTotalHoursInputSubdued={isTotalHoursInputSubdued}
            hasVaryingDailyHours={isFullDay && hasDifferentDailyWorkingHours}
            onChangeHoursPerDay={handleHoursPerDayChange}
            onChangeHoursTotal={handleHoursTotalChange}
            onBlurHoursPerDay={handleHoursPerDayBlur}
            onBlurHoursTotal={handleHoursTotalBlur}
          />

          {!isReadOnly && (
            <div className={styles.timeSectionSpecificTimeToggleWrapper}>
              <TextButton
                appearance="flue-fill"
                onClick={handleClickSetSpecificTimeButton}
              >
                <Trans>Specific time</Trans>
              </TextButton>
            </div>
          )}
        </div>
      )}

      {isAllocationByTime && (
        <div className={styles.timeSectionColumn}>
          <InputAllocationTimeRange
            hoursPerDay={hoursPerDay}
            startTime={startTime}
            isReadOnly={isReadOnly}
            is24HoursTimeFormat={is24HoursTimeFormat}
            onChange={handleTimeRangeChange}
          />
          {!isReadOnly && (
            <div className={styles.timeSectionTotalHoursToggleWrapper}>
              <TextButton
                appearance="flue-fill"
                onClick={handleClickSetTotalHoursButton}
              >
                <Trans>Total hours</Trans>
              </TextButton>
            </div>
          )}
        </div>
      )}

      <div className={styles.dateRangeColumn}>
        <InputAllocationDateRange
          startDate={startDate}
          endDate={endDate}
          numberOfAllocationDaysInDateRange={numberOfAllocationDays}
          isReadOnly={isReadOnly}
          getIsDateDisabled={getIsDateDisabled}
          onChange={handleDateRangeChange}
        />
        {isIntervalRepeatable && (
          <InputAllocationRepeat
            getIsDateDisabled={getIsDateDisabled}
            hasRecurringExternalCalendar={hasRecurringExternalCalendar}
            isIntegrationSyncLocked={isIntegrationSyncLocked}
            isReadOnly={isReadOnly}
            minRepeatDate={endDate}
            onChangeRepeatEndDate={handleRepeatEndDateChange}
            onChangeRepeatInterval={handleRepeatIntervalChange}
            repeatEndDate={repeatEndDate}
            repeatState={repeatState}
            repeatTimes={repeatTimes}
            startDate={startDate}
          />
        )}
      </div>
    </motion.div>
  );
});
