import React, { useState } from 'react';
import { t, Trans } from '@lingui/macro';
import { useQuery } from '@tanstack/react-query';

import { ConfirmDeleteTeam } from '@float/common/components/ConfirmDeleteTeam';
import request from '@float/common/lib/request';
import { FloatLogo } from '@float/ui/components/FloatLogo';
import { Button } from '@float/ui/deprecated';

import {
  StyledBackground,
  StyledCloudsIllustration,
  StyledContainer,
  StyledDialog,
  StyledHeader,
  StyledKeyholeIllustration,
} from './styles';

import WavingHandIllustration from './assets/WavingHandIllustration.svg';

async function getReactivateData() {
  const res = await request.get('me-api/reactivate-subscription/json', null, {
    hostname: '',
    version: '',
  });

  return res as { name: string; needsNewPayment: boolean };
}

export function ReactivateSubscription() {
  const { data } = useQuery({
    queryKey: ['reactivate-subscription'],
    queryFn: getReactivateData,
  });

  const [modal, setModal] = useState<'delete-team' | null>(null);

  const contextCopy = data?.needsNewPayment
    ? t`Your subscription is currently paused. We'll need you to review your plan and billing information to reactivate.`
    : t`Your subscription is currently paused.`;

  return (
    <main className="page-multi-account-login">
      <StyledContainer>
        {modal === 'delete-team' && (
          <ConfirmDeleteTeam
            onClose={() => setModal(null)}
            companyName={data?.name || ''}
          />
        )}

        <StyledHeader>
          <FloatLogo height={26} />
        </StyledHeader>

        <StyledDialog>
          <h1>
            <Trans>Reactivate your subscription</Trans>
          </h1>

          <img src={WavingHandIllustration} width={408} height={196} />

          <p>{contextCopy}</p>

          <Button as="a" href="/reactivate-subscription/payment">
            Reactivate subscription
          </Button>
          <Button
            appearance="danger-outline"
            onClick={() => setModal('delete-team')}
          >
            <Trans>Delete your team</Trans>
          </Button>

          <p className="small">
            <Trans>
              Deleting your team will delete all of the <b>{data?.name}</b>{' '}
              information and close the account. This can't be undone.
            </Trans>
          </p>
        </StyledDialog>
        <StyledBackground>
          <StyledCloudsIllustration />
          <StyledKeyholeIllustration />
        </StyledBackground>
      </StyledContainer>
    </main>
  );
}

export default ReactivateSubscription;
